import { Component, AfterViewChecked, OnInit, AfterViewInit, Inject } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/platform-browser';
import { Labels } from 'app/labels';
import { CommunicationService } from '../service/communicationService.service';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';

@Component({
    selector: 'app-navbar',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit, AfterViewInit {
    width: number;
    labels: any;
    showMenu: boolean = false
    componentAlive: boolean = true

    constructor(private communicationService: CommunicationService, @Inject(DOCUMENT) public document: Document,
        private router: Router, public papillonLabels: Labels, public meta: Meta, public title: Title, private location: Location) {
        this.labels = papillonLabels.labels;
        this.setMetaData();

    }

    setMetaData() {
        this.title.setTitle("Papillon Skopje");
        this.meta.updateTag({ name: 'description', content: this.labels.about.about });
        this.meta.updateTag({ name: 'keywords', content: this.labels.keywords });
    }

    ngOnInit() {
        this.width = document.body.clientWidth;
        this.labels = this.papillonLabels.labels;

        //$.getScript('./assets/js/jquery.min.js');
        $.getScript('./assets/js/jquery.dropotron.min.js');
        $.getScript('./assets/js/jquery.scrolly.min.js');
        $.getScript('./assets/js/jquery.scrollex.min.js');
        // $.getScript('./assets/js/browser.min.js');

        $.getScript('./assets/js/breakpoints.min.js');
        $.getScript('./assets/js/main.js');
    }

    public ngAfterViewInit(): void {
        if (this.router.parseUrl(this.router.url).root.children.primary) {
            let targetElement;
            switch (this.router.parseUrl(this.router.url).root.children.primary.segments[0].path) {
                case "about": {
                    targetElement = this.document.getElementById('about');
                    break;
                }
                case "collections": {

                    if (this.router.parseUrl(this.router.url).root.children.primary.segments[1]) {
                        this.communicationService.emitCollection(this.router.parseUrl(this.router.url).root.children.primary.segments[1].path)
                    }
                    targetElement = this.document.getElementById('collections')
                    break;
                }
                case "contact": {
                    targetElement = this.document.getElementById('info')
                    break;
                }
                case "location": {
                    targetElement = this.document.getElementById('info');
                    let route = this.router.parseUrl(this.router.url).root.children.primary.segments[0].path + '/' + this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;
                    this.communicationService.emitLocation(
                        this.router.parseUrl(this.router.url).root.children.primary.segments[1].path == 'kozle' ?
                            this.labels.info.location.children[0].title : this.labels.info.location.children[1].title, route)
                    break;
                }
                case "news": {
                    targetElement = this.document.getElementById('info');
                    this.communicationService.emitNews(
                        this.router.parseUrl(this.router.url).root.children.primary.segments[1].path == 'komercijalna' ? 
                        this.labels.info.news.children[2] : (this.router.parseUrl(this.router.url).root.children.primary.segments[1].path == 'coats' ? 
                        this.labels.info.news.children[1] : (this.router.parseUrl(this.router.url).root.children.primary.segments[1].path == 'vip' ? 
                        this.labels.info.news.children[3] : this.labels.info.news.children[0]))
                    )
                    break;
                }
                case "clients": {
                    this.communicationService.emitClients(true)
                    break;
                }
            }
            if (targetElement) {
                setTimeout(() => {
                    targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                }, 1000);

            } else {
                window.scrollTo(0, 0);
            }

        }
    }

    emitNews(news) {
        //this.scrollToElement("news")
        this.communicationService.emitNews(news)
    }

    emitClients(emit: boolean) {
        this.communicationService.emitClients(emit)
    }
    emitCollection(type) {
        this.communicationService.emitCollection(type)
    }

    scrollToElement(element): void {
        let el;
        if (element == 'news' || element == 'contact') {
            el = this.document.getElementById("info")
        }
        else {
            el = this.document.getElementById(element)
        }
        if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    translatePage(language) {
        if (language == 'MK') {
            this.communicationService.emitLanguage(false)
            this.labels = this.papillonLabels.translateToMacedonian()
        }
        else if (language == 'EN') {
            this.communicationService.emitLanguage(true)
            this.labels = this.papillonLabels.translateToEnglish()
        }
    }
}

