import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './shared/home/home.component';

const appRoutes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'about', component: HomeComponent },
  {
    path: 'collections', component: HomeComponent,
    children: [
      {
        path: 'men',
        component: HomeComponent
      },
      {
        path: 'unica',
        component: HomeComponent
      },
      {
        path: 'romantica',
        component: HomeComponent
      },
      {
        path: 'poetica',
        component: HomeComponent
      },
      {
        path: 'simpatica',
        component: HomeComponent
      },
      {
        path: 'women',
        component: HomeComponent
      }
    ]
  },
  { path: 'news', component: HomeComponent },
  {
    path: 'location/kozle',
    component: HomeComponent
  },
  {
    path: 'location/biser',
    component: HomeComponent
  },

  {
    path: 'news/covid-19',
    component: HomeComponent
  },
  {
    path: 'news/komercijalna',
    component: HomeComponent
  },
  {
    path: 'news/vip',
    component: HomeComponent
  },
  {
    path: 'news/coats',
    component: HomeComponent
  },
  {
    path: 'news/anniversary',
    component: HomeComponent
  },
  { path: 'contact', component: HomeComponent },
  { path: 'clients', component: HomeComponent },

  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  }

];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}