import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommunicationService } from 'app/shared/service/communicationService.service';
import { Labels } from 'app/labels';
import { Location } from '@angular/common';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnInit {

  componentAlive = true;
  data: any = {};
  width: number;
  labels: any;
  previewPicture: string = '';
  itemIndex: number = 0;

  constructor(private communicationService: CommunicationService, public papillonLabels: Labels, private cdr: ChangeDetectorRef,
    private location: Location) {
      this.labels = papillonLabels.labels;
      this.communicationService.onLanguageSelected$.takeWhile(() => this.componentAlive)
    .subscribe((data) => {
        if (data) {
            this.labels = this.papillonLabels.translateToEnglish();
        }
        else {
            this.labels = this.papillonLabels.translateToMacedonian();
        }
    });
    this.communicationService.onChooseClientDrawer$.takeWhile(() => this.componentAlive)
      .subscribe((data) => {
        if (data) {
          this.location.replaceState('clients');
          if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
          }
          const element = document.getElementById('clients-drawer');
          if (element) {
            element.style.visibility = 'visible';
            element.classList.add('open');
          }
        }
      });
  }
  ngOnInit() {
    $.getScript('./../assets/js/notification-sidebar.js');
    const element = document.getElementById('clients-drawer');
    if (element) {
      element.style.visibility = 'hidden';
    }
    this.width = document.body.clientWidth;
  }

  dismiss() {
    this.location.replaceState('');
    const element = document.getElementById('clients-drawer');
    element.style.visibility = 'hidden';
  }

  previous(i, array) {
    if (i == 0) {
      this.itemIndex = array.length - 1
    } else {
      this.itemIndex = this.itemIndex - 1
    }
    this.previewPicture = array[this.itemIndex].url
  }

  next(i, array) {
    if (i == array.length - 1) {
      this.itemIndex = 0
    } else {
      this.itemIndex = this.itemIndex + 1
    }
    this.previewPicture = array[this.itemIndex].url
  }

}
