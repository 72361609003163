import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommunicationService } from 'app/shared/service/communicationService.service';
import { Labels } from 'app/labels';
import { Location } from '@angular/common';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})

export class GalleryComponent implements OnInit {

  componentAlive = true;
  width: number;
  labels: any;
  previewPicture: string = '';
  itemIndex: number = 0;
  data: any;
  loadComponent = false

  constructor(private communicationService: CommunicationService, public papillonLabels: Labels, private cdr: ChangeDetectorRef,
    private location: Location) {
      this.labels = papillonLabels.labels;
      this.communicationService.onLanguageSelected$.takeWhile(() => this.componentAlive)
    .subscribe((data) => {
        if (data) {
            this.labels = this.papillonLabels.translateToEnglish();
        }
        else {
            this.labels = this.papillonLabels.translateToMacedonian();
        }
    });
    this.communicationService.onChooseCollectionDrawer$.takeWhile(() => this.componentAlive)
      .subscribe((data) => {
        if (data) {
          switch (data) {
            case 'men': {
              this.data = this.labels.gallery.men;
              break;
            }
            case 'unica': {
              this.data = this.labels.gallery.unica;
              break;
            }
            case 'romantica': {
              this.data = this.labels.gallery.romantica;
              break;
            }
            case 'simpatica': {
              this.data = this.labels.gallery.simpatica;
              break;
            }
            case 'women': {
              this.data = this.labels.gallery.women;
              break;
            }
            case 'poetica': {
              this.data = this.labels.gallery.poetica;
              break;
            }
          }
          this.previewPicture = this.data.children[0].url;
          this.loadComponent = true;
          this.location.replaceState("collections/" + data);
          if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
          }
          const element = document.getElementById('gallery-drawer');
          if (element) {
            element.style.visibility = 'visible';
            element.classList.add('open');
          }
        }
      });
  }
  ngOnInit() {
    $.getScript('./../assets/js/notification-sidebar.js');
    const element = document.getElementById('gallery-drawer');
    if (element) {
      element.style.visibility = 'hidden';
    }
    this.width = document.body.clientWidth;
  }

  dismiss() {
    this.location.replaceState('');
    const element = document.getElementById('gallery-drawer');
    element.style.visibility = 'hidden';
    this.loadComponent = false;
  }

  openImage(url) {
    this.previewPicture = url

  }

  previous(i, array) {
    if (i == 0) {
      this.itemIndex = array.length - 1
    } else {
      this.itemIndex = this.itemIndex - 1
    }
    this.previewPicture = array[this.itemIndex].url
  }

  next(i, array) {
    if (i == array.length - 1) {
      this.itemIndex = 0
    } else {
      this.itemIndex = this.itemIndex + 1
    }
    this.previewPicture = array[this.itemIndex].url
  }
}
