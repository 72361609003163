import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from "./home/home.component";
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { CommunicationService } from './service/communicationService.service';
import { CollectionsComponent } from './collections/collections.component';
import { AboutComponent } from './about/about.component';
import { FooterComponent } from './footer/footer.component';
import { GalleryComponent } from './drawers/gallery/gallery.component';
import { LocationComponent } from './drawers/location/location.component';
import { ClientsComponent } from './drawers/clients/clients.component';
import { NewsComponent } from './drawers/news/news.component'

@NgModule({
    exports: [
        CommonModule,
        NotificationSidebarComponent,
        NgbModule,
        HomeComponent,
        CollectionsComponent,
        AboutComponent,
        FooterComponent,
        GalleryComponent,
        LocationComponent,
        ClientsComponent,
        NewsComponent
    ],
    imports: [
        RouterModule,
        CommonModule,
        NgbModule

    ],
    declarations: [
        NotificationSidebarComponent,
        HomeComponent,
        CollectionsComponent,
        AboutComponent,
        FooterComponent,
        GalleryComponent,
        LocationComponent,
        ClientsComponent,
        NewsComponent
    ],
    providers: [
        CommunicationService
    ]
})
export class SharedModule { }
