import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Labels } from 'app/labels';
import { CommunicationService } from '../service/communicationService.service';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit {

  labels: any;
  componentAlive: boolean = true;
  collections: any = [];

  constructor(public papillonLabels: Labels, private communicationService: CommunicationService) {
    this.labels = papillonLabels.labels;
    this.communicationService.onLanguageSelected$.takeWhile(() => this.componentAlive)
      .subscribe((data) => {
        if (data) {
          this.labels = this.papillonLabels.translateToEnglish();
          this.collections = [];
          this.labels.menu.forEach(element => {
            if (element.path == 'collections') {
              if (element.children.length) {
                element.children.forEach(element => {
                  this.collections.push(element)
                });
              }
            }
          });
        }
        else {
          this.labels = this.papillonLabels.translateToMacedonian();
          this.collections = [];
          this.labels.menu.forEach(element => {
            if (element.path == 'collections') {
              if (element.children.length) {
                element.children.forEach(element => {
                  this.collections.push(element)
                });
              }
            }
          });
        }
      });
  }

  ngOnInit() {
    this.labels.menu.forEach(element => {
      if (element.path == 'collections') {
        if (element.children.length) {
          element.children.forEach(element => {
            this.collections.push(element)
          });
        }
      }
    });
  }

  emitCollection(type) {
    this.communicationService.emitCollection(type)
  }

}
