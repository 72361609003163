import { Injectable } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { Title } from '@angular/platform-browser';

@Injectable()
export class CommunicationService {
    private onLanguageSelected = new Subject<any>();
    onLanguageSelected$ = this.onLanguageSelected.asObservable();
    emitLanguage(notification: boolean) {
        this.onLanguageSelected.next(notification);
    }

    private onChooseLocationDrawer = new Subject<any>();
    onChooseLocationDrawer$ = this.onChooseLocationDrawer.asObservable();
    emitLocation(title: any, route: any) {
        const data = {
            "title": title,
            "route": route
        }
        this.onChooseLocationDrawer.next(data);
    }

    private onChooseNewsDrawer = new Subject<any>();
    onChooseNewsDrawer$ = this.onChooseNewsDrawer.asObservable();
    emitNews(news: any) {
        this.onChooseNewsDrawer.next(news);
    }

    private onChooseClientDrawer = new Subject<any>();
    onChooseClientDrawer$ = this.onChooseClientDrawer.asObservable();
    emitClients(emit: boolean) {
        this.onChooseClientDrawer.next(emit);
    }

    private onChooseCollectionDrawer = new Subject<any>();
    onChooseCollectionDrawer$ = this.onChooseCollectionDrawer.asObservable();
    emitCollection(type: any) {
        this.onChooseCollectionDrawer.next(type);
    }
}

