import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CommunicationService } from 'app/shared/service/communicationService.service';
import { Labels } from 'app/labels';
import { Location } from '@angular/common';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.scss']
})
export class LocationComponent implements OnInit {
  
    componentAlive = true;
    data: any = {};
    width: number;
    labels: any;
  
    constructor(private communicationService: CommunicationService, public papillonLabels: Labels, private cdr: ChangeDetectorRef,
      private location: Location)
    { 
      this.labels = papillonLabels.labels;
      this.communicationService.onLanguageSelected$.takeWhile(() => this.componentAlive)
    .subscribe((data) => {
        if (data) {
            this.labels = this.papillonLabels.translateToEnglish();
        }
        else {
            this.labels = this.papillonLabels.translateToMacedonian();
        }
    });
      this.communicationService.onChooseLocationDrawer$.takeWhile(() => this.componentAlive)
        .subscribe((data) =>
        {
          if (data) {
            this.data = data
            this.location.replaceState(data.route);
            if (!this.cdr['destroyed']) {
              this.cdr.detectChanges();
            }
            const element = document.getElementById('location-drawer');
            if (element) {
              element.style.visibility = 'visible';
              element.classList.add('open');
            }
          }
        });
    }
    ngOnInit()
    {
      $.getScript('./../assets/js/notification-sidebar.js');
      const element = document.getElementById('location-drawer');
      if (element) {
        element.style.visibility = 'hidden';
      }
      this.width = document.body.clientWidth;
    }
  
    dismiss()
    {
      this.location.replaceState('');
      const element = document.getElementById('location-drawer');
      element.style.visibility = 'hidden';
    }
}
