import { Component, OnInit } from '@angular/core';
import { Labels } from 'app/labels';
import { CommunicationService } from '../service/communicationService.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  labels: any;
  componentAlive: boolean = true;

  constructor(public papillonLabels: Labels, private communicationService: CommunicationService) {
    this.labels = papillonLabels.labels;
    this.communicationService.onLanguageSelected$.takeWhile(() => this.componentAlive)
    .subscribe((data) => {
        if (data) {
            this.labels = this.papillonLabels.translateToEnglish();
        }
        else {
            this.labels = this.papillonLabels.translateToMacedonian();
        }
    });
  }
  ngOnInit() {
  }

  emitLocation(title, route){
    this.communicationService.emitLocation(title, route)
  }
  emitNews(news){
    this.communicationService.emitNews(news)
  }
  

}
